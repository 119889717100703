import "./custom-print.scss";

import * as React from "react";
import { observer } from "mobx-react";
import { Button, Checkbox, Drawer, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { CustomDocumentDeliveryMode, CustomDocumentLayout, customDocumentPrinterModel } from "./custom-document-printer-model";
import { t } from "i18next";

@observer
export class CustomDocumentPrinter extends React.Component<{}> {
    public componentDidMount() {
        customDocumentPrinterModel.readCustomFilenameSetting();
    }

    public render() {
        return <Drawer
            disableEscapeKeyDown
            anchor="right"
            open={customDocumentPrinterModel.isCustomPrinterMenuOpened}
            data-testid="custom-print-drawer"
        >
            <div className={"custom-print"}>
                <Typography className={"custom-print__headline"} component={"h2"}>
                    {t("components.customDocumentPrint.title")}
                </Typography>

                <div className="custom-print__option">
                    <Typography className="custom-print__option-title">{t("components.customDocumentPrint.components.title")}</Typography>
                    {!customDocumentPrinterModel.disableLabelingAction && <FormControlLabel
                        control={
                            <Checkbox
                                data-testid={"custom-print-include-label"}
                                color="primary"
                                checked={customDocumentPrinterModel.optionIncludeLabel}
                                onChange={(_: React.ChangeEvent<{}>, checked: boolean) => customDocumentPrinterModel.setOptionIncludeLabel(checked)}
                            />
                        }
                        label={t("components.customDocumentPrint.components.label")}
                    />}
                    <FormControlLabel
                        control={
                            <Checkbox
                                data-testid={"custom-print-include-preparation-order"}
                                color="primary"
                                checked={customDocumentPrinterModel.optionIncludePreparationOrder}
                                onChange={(_: React.ChangeEvent<{}>, checked: boolean) => customDocumentPrinterModel.setOptionIncludePreparationOrder(checked)}
                            />
                        }
                        label={t("components.customDocumentPrint.components.preparationOrder")}
                    />
                </div>

                {customDocumentPrinterModel.optionIncludeLabel && customDocumentPrinterModel.optionIncludePreparationOrder &&
                <div className="custom-print__option">
                    <Typography className="custom-print__option-title">{t("components.customDocumentPrint.layout.title")}</Typography>
                    <RadioGroup
                        value={`${customDocumentPrinterModel.layout}`}
                        className="custom-print__label-container"
                        onChange={async event => await this.changeLayout(event)}>
                        <FormControlLabel
                            value={CustomDocumentLayout.LabelWithPreparationOrderOnSamePage}
                            data-testid={"custom-print-layout-one-page"}
                            control={<Radio />}
                            label={t("components.customDocumentPrint.layout.onePage")}
                        />
                        <FormControlLabel
                            value={CustomDocumentLayout.LabelWithPreparationOrderConsecutivePages}
                            data-testid={"custom-print-layout-consecutive-pages"}
                            control={<Radio />}
                            label={t("components.customDocumentPrint.layout.consecutivePages")}
                        />
                    </RadioGroup>
                </div>}

                <div className="custom-print__option">
                    <Typography className="custom-print__option-title">{t("components.customDocumentPrint.delivery.title")}</Typography>
                    <RadioGroup
                        value={`${customDocumentPrinterModel.deliveryMode}`}
                        data-testid={"custom-print-delivery-mode"}
                        className="custom-print__label-container"
                        onChange={async event => await this.changeDeliveryMode(event)}>
                        <FormControlLabel
                            value={CustomDocumentDeliveryMode.Print}
                            control={<Radio />}
                            label={t("components.customDocumentPrint.delivery.print")}
                            disabled={!customDocumentPrinterModel.isPrinterConfigured}
                        />
                        <FormControlLabel
                            value={CustomDocumentDeliveryMode.Download}
                            control={<Radio />}
                            label={t("components.customDocumentPrint.delivery.download")}
                        />
                    </RadioGroup>
                </div>

                {customDocumentPrinterModel.deliveryMode === "download" && customDocumentPrinterModel.parcelList.length > 1 &&
                <div className="custom-print__option">
                    <Typography className="custom-print__option-title">{t("components.customDocumentPrint.merge.title")}</Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                data-testid={"custom-print-merge-pages"}
                                color="primary"
                                checked={customDocumentPrinterModel.mergeToSingleFile}
                                onChange={(_: React.ChangeEvent<{}>, checked: boolean) => customDocumentPrinterModel.setMergeToSingleFile(checked)}
                            />
                        }
                        label={t("components.customDocumentPrint.merge.mergeToSingleFile")}
                    />
                </div>}

                {customDocumentPrinterModel.deliveryMode === "download"
                    && !(customDocumentPrinterModel.optionIncludeLabel && customDocumentPrinterModel.optionIncludePreparationOrder)
                    && !customDocumentPrinterModel.mergeToSingleFile &&
                    <div className="custom-print__option">
                        <Typography className="custom-print__option-title">{t("components.customDocumentPrint.customFilename.title")}</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    data-testid={"custom-print-custom-filename-pages"}
                                    color="primary"
                                    checked={customDocumentPrinterModel.useCustomFilename}
                                    onChange={(_: React.ChangeEvent<{}>, checked: boolean) => customDocumentPrinterModel.setUseCustomFilename(checked)}
                                />
                            }
                            label={t("components.customDocumentPrint.customFilename.useCustomFilename")}
                        />
                        <TextField data-testid={"custom-print-custom-filename"}
                                   variant={"outlined"}
                                   fullWidth={true}
                                   size={"small"}
                                   disabled={!customDocumentPrinterModel.useCustomFilename}
                                   multiline={true}
                                   maxRows={2}
                                   value={customDocumentPrinterModel.customFilename}
                                   onChange={e => customDocumentPrinterModel.setCustomFilename(e.target.value)}
                        />
                    </div>}

                <div className={"custom-print__buttons-container"}>
                    <Button
                        data-testid={"custom-print-create-btn"}
                        variant={"contained"}
                        color={"primary"}
                        onClick={async () => await customDocumentPrinterModel.printDocument()}
                        disabled={!customDocumentPrinterModel.optionIncludeLabel && !customDocumentPrinterModel.optionIncludePreparationOrder}
                        className={"custom-print__button"}
                    >
                        {t("components.customDocumentPrint.submit")}
                    </Button>

                    <Button
                        data-testid={"custom-print-cancel-btn"}
                        variant={"contained"}
                        color={"inherit"}
                        onClick={async () => await customDocumentPrinterModel.closeCustomPrinterMenu()}
                        className={"custom-print__button"}
                    >
                        {t("components.customDocumentPrint.close")}
                    </Button>
                </div>

                {customDocumentPrinterModel.useCustomFilename &&
                    <div>
                        <Typography variant="caption" className="custom-print__option-hint">{t("components.customDocumentPrint.customFilename.hint")}</Typography>
                    </div>
                }
            </div>
        </Drawer>;
    }

    private changeLayout(e: any) {
        customDocumentPrinterModel.setLayout(e.target.value);
    }

    private changeDeliveryMode(e: any) {
        customDocumentPrinterModel.setDeliveryMode(e.target.value);
    }
}
