import React, { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer } from "react";
import { RemoteDocumentJob } from "./remote-document-job";
import { IPrintingContextReducerPayload, RemotePrintingContextReducer } from "./remote-printing-context-reducer";
import { RemoteDocumentsPrinter } from "./remote-documents-printer";
import { remotePrintingService } from "../../../../front-office/services/remote-printing";
import { Guid } from "guid-typescript";
import { useContextTypeSafe } from "../../../hooks/use-context-type-safe";
import { context } from "../../../../context/context";
import { Permission } from "../../../../context/permission";

interface IRemotePrintingContextProvider {
    children: ReactNode;
}

export interface IRemotePrintingContext {
    id: Guid;
    queue: RemoteDocumentJob[];
}

const defaultRemotePrintingContext: IRemotePrintingContext = {
    id: Guid.createEmpty(),
    queue: [],
};

const RemotePrintingContext = createContext<IRemotePrintingContext>(defaultRemotePrintingContext);
const RemotePrintingContextDispatcher = createContext<Dispatch<IPrintingContextReducerPayload> | null>(null);

export const RemotePrintingContextProvider = (props: IRemotePrintingContextProvider) => {
    const [printingContext, printingContextDispatcher] = useReducer(RemotePrintingContextReducer, defaultRemotePrintingContext);
    const documentsPrinter = new RemoteDocumentsPrinter();

    useEffect(() => {
        setInterval(async () => {
            if (!context.isAuthenticated) {
                return;
            }

            if (context.hasPermission(Permission.FeatureBlueBrand)) {
                return;
            }

            const documents = await documentsPrinter.listAll();

            printingContextDispatcher({
                action: "push",
                documents,
            });
        }, 30000);
    }, []);

    useEffect(() => {
        if (printingContext.id.isEmpty()) {
            return;
        }

        (async () => {
            if (printingContext.queue.length > 0) {
                const job = printingContext.queue[0];

                await documentsPrinter.printDocument(job.printer, job.documentId);
                await remotePrintingService.confirmPrintout(job.documentId);

                printingContextDispatcher({
                    action: "complete",
                    documentId: job.documentId,
                });
            }
        })();
    }, [printingContext.id]);

    return <RemotePrintingContext.Provider value={printingContext}>
        <RemotePrintingContextDispatcher.Provider value={printingContextDispatcher}>
            {props.children}
        </RemotePrintingContextDispatcher.Provider>
    </RemotePrintingContext.Provider>;
};

export const useRemotePrintingContext = () => {
    return useContext(RemotePrintingContext);
};

export const useRemotePrintingContextDispatcher = () => {
    return useContextTypeSafe(RemotePrintingContextDispatcher);
};
