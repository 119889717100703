import { action, computed, observable } from "mobx";
import { Parcel } from "../../../model/parcel";
import { documentPrinter, DocumentPrintoutMode } from "./document-printer";
import { IParcelDocumentProvider } from "./document-provider/parcel-document-provider-interface";
import { context } from "../../../../context/context";
import { qzManager } from "../../../qz/qz-manager";
import { pdfPreparationOrderAndLabelProvider } from "./document-provider/pdf-preparation-order-and-label-provider";
import { IMultiParcelDocumentProvider } from "./document-provider/multiparcel-document-provider-interface";
import { preparationOrderAndLabelOnOnePageProvider } from "./document-provider/preparation-order-and-label-on-one-page-provider";
import { pdfLabelProvider } from "./document-provider/pdf-label-provider";
import { t } from "i18next";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { preparationOrderProvider } from "./document-provider/preparation-order-provider";
import { Permission } from "../../../../context/permission";

interface ICustomFilenameSettings {
    useCustomFilename: boolean;
    customFilename: string;
}

class CustomDocumentPrinterModel {
    private _operationCode: string;
    private _batchId: number;
    private _labelDefaultFilenameFormat: string = "label_%VP_OPERATION_CODE%_%VP_BATCH_ID%_%VP_PARCEL_ID%_%TIMESTAMP%";
    private _preparationOrderDefaultFilenameFormat: string = "preparation-order-%VP_OPERATION_CODE%-%VP_BATCH_ID%-%TIMESTAMP%";

    @observable private _isPrinterConfigured: boolean = false;

    @observable private _printerMenuOpened: boolean = false;

    @observable private _optionIncludeLabel: boolean = true;

    @observable private _optionIncludePreparationOrder: boolean = false;

    @observable private _layout: CustomDocumentLayout = CustomDocumentLayout.LabelWithPreparationOrderConsecutivePages;

    @observable private _deliveryMode: CustomDocumentDeliveryMode = CustomDocumentDeliveryMode.Download;

    @observable private _mergeToSingleFile: boolean;

    @observable private _parcelList: Parcel[] = [];

    @observable private _useCustomFilename: boolean;

    @observable private _customFilename: string = this._labelDefaultFilenameFormat;

    @observable private _onDocumentsPrinted: ((value?: (PromiseLike<void> | void)) => void) | null;

    @computed
    public get disableLabelingAction(): boolean {
        return context.hasPermission(Permission.FeatureBlueBrand);
    }

    @computed
    public get isCustomPrinterMenuOpened(): boolean {
        return this._printerMenuOpened;
    }

    @action
    public readCustomFilenameSetting() {
        const customFilenameSettingsJson = localStorage.getItem(`custom_filename_settings_${context.currentUser.name}`);
        if (customFilenameSettingsJson) {
            const customFilenameSettings = JSON.parse(customFilenameSettingsJson) as ICustomFilenameSettings;

            this._useCustomFilename = customFilenameSettings.useCustomFilename;
            this._customFilename = customFilenameSettings.customFilename;
        }
    }

    @action
    public async openCustomPrinterMenu(operationCode: string, batchId: number, parcelList: Parcel[]) {
        this._operationCode = operationCode;
        this._batchId = batchId;
        this._parcelList = parcelList;

        if (this.disableLabelingAction) {
            this._optionIncludeLabel = false;
            this._optionIncludePreparationOrder = true;
        }

        this._printerMenuOpened = true;

        await this.verifyPrinterConfiguration();

        return new Promise(resolve =>
            this._onDocumentsPrinted = resolve);
    }

    @action
    public async verifyPrinterConfiguration() {
        const isQzTrayOpened = await qzManager.qzInitialization();
        this.setIsPrinterConfigured(isQzTrayOpened && context.settings.printerName !== "");

        if (!this._isPrinterConfigured && customDocumentPrinterModel.deliveryMode === CustomDocumentDeliveryMode.Print) {
            customDocumentPrinterModel.setDeliveryMode(CustomDocumentDeliveryMode.Download);
        }
    }

    @action
    public closeCustomPrinterMenu() {
        this._printerMenuOpened = false;
    }

    @computed
    public get optionIncludeLabel(): boolean {
        return this._optionIncludeLabel;
    }

    @action
    public setOptionIncludeLabel(includeLabel: boolean) {
        this._optionIncludeLabel = includeLabel;

        if (includeLabel) {
            this._customFilename = this._labelDefaultFilenameFormat;
        } else if (this._optionIncludePreparationOrder) {
            this._customFilename = this._preparationOrderDefaultFilenameFormat;
        }

        if (this._optionIncludeLabel && this._optionIncludePreparationOrder) {
            this._useCustomFilename = false;
        }
    }

    @computed
    public get optionIncludePreparationOrder(): boolean {
        return this._optionIncludePreparationOrder;
    }

    @action
    public setOptionIncludePreparationOrder(includePreparationOrder: boolean) {
        this._optionIncludePreparationOrder = includePreparationOrder;

        if (includePreparationOrder) {
            this._customFilename = this._preparationOrderDefaultFilenameFormat;
        } else if (this._optionIncludeLabel) {
            this._customFilename = this._labelDefaultFilenameFormat;
        }

        if (this._optionIncludeLabel && this._optionIncludePreparationOrder) {
            this._useCustomFilename = false;
        }
    }

    @computed
    public get layout(): CustomDocumentLayout {
        return this._layout;
    }

    @action
    public setLayout(layout: CustomDocumentLayout) {
        this._layout = layout;
    }

    @computed
    public get deliveryMode(): CustomDocumentDeliveryMode {
        return this._deliveryMode;
    }

    @action
    public setDeliveryMode(deliveryMode: CustomDocumentDeliveryMode) {
        this._deliveryMode = deliveryMode;
    }

    @computed
    public get mergeToSingleFile(): boolean {
        return this._mergeToSingleFile;
    }

    @action
    public setMergeToSingleFile(mergeToSingleFile: boolean) {
        this._mergeToSingleFile = mergeToSingleFile;
    }

    @computed
    public get parcelList(): Parcel[] {
        return this._parcelList;
    }

    @computed
    public get isPrinterConfigured(): boolean {
        return this._isPrinterConfigured;
    }

    @action
    public setIsPrinterConfigured(status: boolean) {
        this._isPrinterConfigured = status;
    }

    @computed
    public get useCustomFilename(): boolean {
        return this._useCustomFilename;
    }

    @action
    public setUseCustomFilename(useCustomFilename: boolean) {
        this._useCustomFilename = useCustomFilename;

        localStorage.setItem(`custom_filename_settings_${context.currentUser.name}`,
            JSON.stringify({ useCustomFilename: this._useCustomFilename, customFilename: this._customFilename}));
    }

    @computed
    public get customFilename(): string {
        return this._customFilename;
    }

    @action
    public setCustomFilename(customFilename: string) {
        this._customFilename = customFilename;

        localStorage.setItem(`custom_filename_settings_${context.currentUser.name}`,
            JSON.stringify({ useCustomFilename: this._useCustomFilename, customFilename: this._customFilename}));
    }

    public async printDocument() {
        this.closeCustomPrinterMenu();

        if (this._deliveryMode === CustomDocumentDeliveryMode.Download && this._mergeToSingleFile) {
            await this.downloadMultipageDocument();
        } else {
            await this.printOrDownloadWithProgressDrawer();
        }

        if (this._onDocumentsPrinted) {
            this._onDocumentsPrinted();
        }
    }

    private async downloadMultipageDocument() {
        const summary = await documentPrinter.downloadMultiparcelDocument(
            this._operationCode,
            this._batchId,
            this._parcelList,
            this.documentStrategy,
        );

        if (summary.nbParcelsPrinted > 0) {
            notificationModel.addSuccessMessage(t(`components.customDocumentPrint.success`, {count: summary.nbParcelsPrinted}));
        }

        if (summary.nbFailedParcels > 0) {
            notificationModel.addErrorMessage(t(`components.customDocumentPrint.errors`, {errorsCount: summary.nbFailedParcels}));
        }
    }

    private async printOrDownloadWithProgressDrawer() {
        await documentPrinter.printOrDownloadWithProgressDrawer(
            this._operationCode,
            this._batchId,
            [...this._parcelList],
            this.documentStrategy,
            this._deliveryMode === CustomDocumentDeliveryMode.Download ? DocumentPrintoutMode.ForceDownload : DocumentPrintoutMode.ForcePrint,
            this._useCustomFilename ? this._customFilename : "",
        );
    }

    private get documentStrategy(): IParcelDocumentProvider & IMultiParcelDocumentProvider {
        if (this._optionIncludePreparationOrder && this._optionIncludeLabel) {
            return this._layout === CustomDocumentLayout.LabelWithPreparationOrderOnSamePage ?
                preparationOrderAndLabelOnOnePageProvider : pdfPreparationOrderAndLabelProvider;
        }

        if (this._optionIncludeLabel) {
            return pdfLabelProvider;
        }

        if (this._optionIncludePreparationOrder) {
            return preparationOrderProvider;
        }

        throw new Error("Unsupported document strategy");
    }
}

export enum CustomDocumentLayout {
    LabelWithPreparationOrderOnSamePage = "one-page",
    LabelWithPreparationOrderConsecutivePages = "consecutive-pages",
}

export enum CustomDocumentDeliveryMode {
    Download = "download",
    Print = "print",
}

export const customDocumentPrinterModel = new CustomDocumentPrinterModel();
